import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import BucketForm from "./components/BucketForm/BucketForm";
import Bucket from "./components/Bucket/Bucket";
import Settings from "./components/Settings/Settings";
import { Message } from "semantic-ui-react";

const BACKEND_BASE_URL = 'https://idfy-share-demo-backend.azurewebsites.net/api/share/buckets';
const BACKEND_CODE = 'HaQ3ilu84KtFk8kDMysr0HS20Uamtd2aCxm0IDVv0G4Q8DUdoxQfVQ==';

class App extends Component {
  state = {
    bucketCreated: false,
    bucket: null,
    errorVisible: false,
    error: null,
    config: this.getInitialConfig()
  };

  getInitialConfig() {
    const savedConfig = localStorage.getItem('config');
    if (savedConfig) {
      return JSON.parse(savedConfig);
    }

    return {
      environment: 'test'
    };
  }

  handleBucketFormSubmitted = async (bucket) => {
    const { config } = this.state;

    this.props.history.push('/bucket');
    this.setState({
      bucketCreated: true,
      bucket: null,
      error: null,
      errorVisible: false
    });


    try {
      const response = await axios.post(`${BACKEND_BASE_URL}?code=${BACKEND_CODE}&env=${config.environment}`, bucket);

      // TODO: File upload
      const bucketId = response.data.id;

      // eslint-disable-next-line no-unused-vars
      const upload = await Promise.all(response.data.content.map((item) => (
        axios.post(`${BACKEND_BASE_URL}/${bucketId}/upload/${item.id}?code=${BACKEND_CODE}&env=${config.environment}`, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }})
      )));

      this.setState({ bucket: response.data });
    } catch (e) {
      console.error(e, 'Error creating shared bucket');
      this.setState({ errorVisible: true, error: 'Failed to create shared bucket. Make sure you have entered valid values in all the required fields.' });
      this.props.history.push('/');
    }
  };

  handleSettingsChanged = (evt, { name, value }) => {
    console.log('settings changed!', name, value);
    this.setState(prevState => {
      const config = {...prevState.config};
      config.environment = value;
      localStorage.setItem('config', JSON.stringify(config));
      return {
        config: config
      }
    });
  };

  handleErrorDismiss = () => {
    this.setState({ errorVisible: false });
  };

  restart = () => {
    this.setState({
      bucketCreated: false,
      bucket: null,
      error: null,
      errorVisible: false
    });
  };

  render() {
    const { bucket, bucketCreated, config } = this.state;

    return (
      <>
        <Layout environment={config.environment}>
          {this.state.errorVisible && (
            <Message
              error
              onDismiss={this.handleErrorDismiss}>
              {this.state.error}
            </Message>
          )}
          <Switch>
            <Route
              path="/"
              render={props =>
                <BucketForm
                  {...props}
                  onSubmit={this.handleBucketFormSubmitted} />}
              exact />
            <Route
              path='/bucket'
              render={props =>
                bucketCreated
                  ? <Bucket
                      {...props}
                      appBaseUrl={config.appBaseUrl}
                      bucket={bucket}
                  />
                  : <Redirect to="/" /> }
            />
          </Switch>
        </Layout>
        <Settings
          onSettingsChanged={this.handleSettingsChanged}
          config={config} />
      </>
    );
  }
}

export default withRouter(App);
