import styled from 'styled-components';
import { Segment } from "semantic-ui-react";

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
`;

export const MainSegment = styled(Segment)`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const EnvironmentInfo = styled.div`
  text-align: center;
  color: rgba(0,0,0,.6);
  margin-top: 8px;
  margin-bottom: 8px;
  span {
    text-transform: capitalize;
  }
`;
