import React, { Component } from 'react';
import { Button, Form, Header, Input, Select } from "semantic-ui-react";
import { idProviders } from "../../shared/idproviders";

const getIdProviderOptions = () => {
  const providers = [ ...idProviders ];
  return providers.map(provider => ({
    key: provider.id,
    text: provider.name,
    value: provider.id
  }));
};

class BucketForm extends Component {
  state = {
    formData: {
      filesCount: '1',
      senderEmail: '',
      senderMobileCountryCode: '',
      senderMobileNumber: '',
      recipientFirstName: '',
      recipientLastName: '',
      recipientEmail: '',
      recipientMobileCountryCode: '',
      recipientMobileNumber: '',
      recipientIdentityProviders: ['NoBankIdMobile'],
      recipientAuthSsn: '',
      recipientAuthDateOfBirth: '',
      recipientAuthIdentityProviderUniqueId: ''
    }
  };

  handleChange = (evt, { name, value }) => {
    this.setState(prevState => {
      const formData = {...prevState.formData};
      formData[name] = value;
      return {
        formData: formData
      }
    });
  };

  submit = () => {
    const { formData } = this.state;

    const expires = new Date();
    expires.setUTCHours(23, 59, 59);

    const bucketData = {
        information: {
          title: "Secure Share Demo",
          description: "Just a demo of secure share."
        },
        content: [...new Array(Number(formData.filesCount))].map((item, index) => ({
          id: `${index + 1}`,
          name: `test-file-${index + 1}.pdf`,
          type: "file"
        })),
        contactDetails: {
          name: "Idfy Norge AS",
          phone: `${formData.senderMobileCountryCode}${formData.senderMobileNumber}`,
          email: formData.senderEmail
        },
        recipients: [
          {
            firstName: formData.recipientFirstName,
            lastName: formData.recipientLastName,
            email: formData.recipientEmail,
            expires: expires.toISOString(),
            mobile: {
              countryCode: formData.recipientMobileCountryCode,
              number: formData.recipientMobileNumber
            },
            authentication: formData.recipientIdentityProviders.map((r) => ({
              identityProvider: r,
              identityProviderUniqueId: formData.recipientAuthIdentityProviderUniqueId ? formData.recipientAuthIdentityProviderUniqueId : undefined,
              ssn: formData.recipientAuthSsn ? formData.recipientAuthSsn : undefined,
              dateOfBirth: formData.recipientAuthDateOfBirth ? formData.recipientAuthDateOfBirth : undefined
            })),
            notification: {
              setup: {
                request: "SendBoth",
                reminder: "Off"
              },
              language: "En"
            },
            oneTimeLogin: false
          }
        ]
    };

    this.props.onSubmit(bucketData);
  };

  shouldComponentUpdate(prevProps, prevState) {
    return this.state.formData !== prevState.formData;
  }

  componentDidUpdate() {
    console.log('[BucketForm] componentDidUpdate');
  }

  render() {
    const { formData } = this.state;
    const idProviders = getIdProviderOptions();

    return (
      <Form onSubmit={this.submit} style={{ textAlign: 'left' }}>
        <div className="ui three column doubling stackable grid">
          <div className="column">
            <Header as="h3">Sender</Header>
            <Form.Field required>
              <label>Email</label>
              <Input
                name='senderEmail'
                type='text'
                value={formData.senderEmail}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Country code</label>
              <Input
                name='senderMobileCountryCode'
                type='text'
                value={formData.senderMobileCountryCode}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Mobile number</label>
              <Input
                name='senderMobileNumber'
                type='text'
                value={formData.senderMobileNumber}
                onChange={this.handleChange} />
            </Form.Field>
            <Header as="h3">Files</Header>
            <Form.Field required>
              <label>Number of files</label>
              <Select
                name='filesCount'
                options={['1', '2', '3', '4', '5'].map((value) => ({
                  key: value, text: value, value: value
                }))}
                value={formData.filesCount}
                onChange={this.handleChange} />
            </Form.Field>
          </div>
          <div className="column">
            <Header as="h3">Recipient</Header>
            <Form.Field required>
              <label>First name</label>
              <Input
                name='recipientFirstName'
                type='text'
                value={formData.recipientFirstName}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field required>
              <label>Last name</label>
              <Input
                name='recipientLastName'
                type='text'
                value={formData.recipientLastName}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field required>
              <label>Email</label>
              <Input
                name='recipientEmail'
                type='text'
                value={formData.recipientEmail}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Country code</label>
              <Input
                name='recipientMobileCountryCode'
                type='text'
                value={formData.recipientMobileCountryCode}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Mobile number</label>
              <Input
                name='recipientMobileNumber'
                type='text'
                value={formData.recipientMobileNumber}
                onChange={this.handleChange} />
            </Form.Field>

          </div>
          <div className="column">
            <Header as="h3">Auth</Header>
            <Form.Field>
              <label>eID providers</label>
              <Select
                name='recipientIdentityProviders'
                placeholder='Select eID providers'
                multiple
                options={idProviders}
                value={formData.recipientIdentityProviders}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Ssn</label>
              <Input
                name='recipientAuthSsn'
                type='text'
                value={formData.recipientAuthSsn}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Date of birth</label>
              <Input
                name='recipientAuthDateOfBirth'
                placeholder="ddMMyy"
                type='text'
                value={formData.recipientAuthDateOfBirth}
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Identity provider unique id</label>
              <Input
                name='recipientAuthIdentityProviderUniqueId'
                type='text'
                value={formData.recipientAuthIdentityProviderUniqueId}
                onChange={this.handleChange} />
            </Form.Field>
          </div>

        </div>
        <Button
          primary
          type='submit'
          style={{marginTop: '25px'}}
          fluid>Create shared bucket</Button>
      </Form>
    );
  }
}

export default BucketForm;
