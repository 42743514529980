import React from 'react';
import { Form, Header, Modal, Select } from "semantic-ui-react";
import { SettingsButton } from "./styles";

const environments = [
  { key: 'dev', value: 'dev', text: 'Dev' },
  { key: 'test', value: 'test', text: 'Test' },
  { key: 'prod-test', value: 'prod-test', text: 'Prod (test eID)' },
  { key: 'prod-prod', value: 'prod-prod', text: 'Prod (prod eID)' },
];

const Settings = ({ config, onSettingsChanged }) => {
  return (
    <Modal size="tiny" trigger={<SettingsButton title="Settings" primary circular icon="settings" />}>
      <Modal.Content>
        <Header as='h3'>Settings</Header>
        <Form>
          <Form.Field>
            <label>Environment</label>
            <Select
              name="environment"
              options={environments}
              placeholder='Select environment'
              value={config.environment}
              onChange={onSettingsChanged}
              />
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default Settings;
