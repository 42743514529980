import React from 'react';
import { Header } from "semantic-ui-react";
import { EnvironmentInfo, HeaderWrapper, InnerWrapper, MainSegment, Wrapper } from "./styles";
import { Link } from "react-router-dom";

const Layout = ({ children, environment }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <HeaderWrapper>
          <Header as='h2' color='blue' textAlign='center'>
            <Link to='/'>
              Idfy Secure Share Demo
            </Link>
          </Header>
        </HeaderWrapper>
        <MainSegment>
          {children}
        </MainSegment>
        <EnvironmentInfo>
          Current environment: <span>{environment}</span>
        </EnvironmentInfo>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Layout;
