import React, { Component } from 'react';
import { Loader, Header } from "semantic-ui-react";
import { BucketWrapper } from "./styles";
import { LoaderWrapper } from "../../shared/styles";

class Bucket extends Component {
  render() {
    const { bucket } = this.props;

    if (!bucket) {
      return (
        <LoaderWrapper>
          <Loader active inline='centered'>
            Creating shared bucket...
          </Loader>
        </LoaderWrapper>
      )
    }

    const { url } = bucket.recipients[0];

    return (
      <BucketWrapper>
        <Header as="h2">The shared bucket is created</Header>
        <p>Open <a href={url} target="_blank" rel="noopener noreferrer">this link</a> and log in to access the shared file(s).</p>
      </BucketWrapper>
    );
  }
}

export default Bucket;
